<template>
  <HxTable v-model:columns="columns" :reload="loadData">
    <template #search>
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="">
          <a-select :options="auditArr" @change="auditChange" style="width:120px" placeholder="审核状态"/>
        </a-form-item>
        <a-form-item label="">
          <a-select allowClear v-model:value="searchForm.settlementMode"
            :options="$store.state.enumAll.CustomerSettlementEnum" placeholder="结算方式" @change="settlementChange"
            style="width:140px" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.carrierName" placeholder="承运商名称"/>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.charge" placeholder="负责人"/>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.chargeMobile" placeholder="负责人电话"/>
        </a-form-item>
        <a-space>
          <a-button  :loading="loading" type="primary" @click="onSearch" :disabled="loading">
            查询
          </a-button>
          <a-button @click="reset">
            重置
          </a-button>
        </a-space>
      </a-form>
    </template>

    <a-table size="small" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :columns="columns" :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination"
      :loading="loading" @change="handleTableChange">
      <template #status="{record}">
        <div>
          <span :style="{color: colorList[record.status.value]}">{{ record.status.label }}</span>
        </div>
      </template>
      <template #operation="{ record }">
        <!-- <span v-if="record.status != null && record.status.value === 0">
          <a @click="onExamine(record)">审核</a>
        </span>
        <a-divider type="vertical" /> -->
        <span>
          <a @click="onSee(record)">查看</a>
        </span>
      </template>
    </a-table>
  </HxTable>
  <a-modal :width="600" v-model:visible="addWindow" footer="">
    <template #title>
      <div style="font-size:20px">
        审批承运商
      </div>
    </template>
    <a-form layout="vertical" ref="formRef" :model="approvalForm" :rules="rules">
      <a-form-item label="审核结果" :name="['status', 'value']">
        <a-select v-model:value="approvalForm.status.label" :options="approvalEnum" placeholder="请选择审核结果"
          @change="onApprovalEnum" />
      </a-form-item>
      <a-form-item label="原因" name="name" v-if="approvalForm.status.value === 2">
        <a-input v-model:value="approvalForm.refuseReason" />
      </a-form-item>
    </a-form>
    <div style="display:flex;justify-content: flex-end;margin-top:15px">
      <a-button size="large" style="margin-right:15px" @click="addWindow = false">取消</a-button>
      <a-button size="large" class="searchLoading-button" @click="onApproval()">确认</a-button>
    </div>
  </a-modal>
  <DetailData @changeData="changeData" ref="_detailData" :windowShow="detailShow" :carrierId="carrierId" @statusShow="closeWindow" />
  <!-- <a-modal :width="800" v-model:visible="seeWindow" footer="">
    <template #title>
      <div style="font-size:20px">
        承运商详情
      </div>
    </template>
  </a-modal> -->
</template>

<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import HxTable from '@/components/HxTable'
import CarrierDetail from '../toolDetail/index.vue'
import DetailData from './detailData'
import { mobileValidate } from '@/utils/validate'
import { detailData, getPage, approval as approvalAdd } from '@/api/carrier/carrierList'
import { fileUploadPublic as fileUpload } from '@/api/global'
import { message } from 'ant-design-vue'

export default {
  components: {
    HxTable,
    CarrierDetail,
    LoadingOutlined,
    PlusOutlined,
    DetailData
  },
  setup () {
    const formRef = ref()
    const _detailData = ref()
    const store = useStore()
    const state = reactive({
      carrierId: '',
      addWindow: false,
      seeWindow: false,
      loading: false,
      detailShow: false,
      fromData: {},
      carrierData: {},
      licenseFile: [],
      colorList: {
        // 2 未通过   1 通过   0 审核中
        0: '#1890FF',
        1: '#1BBA12',
        2: '#F70303'
      },
      listData: [],
      selectedRowKeys: [],
      CustomerSettlementEnum: [],
      searchForm: {
        carrierName: '',
        charge: '',
        chargeMobile: ''
      },
      approvalEnum: [
        {
          label: '同意',
          value: 1
        },
        {
          label: '拒绝',
          value: 2
        }
      ],
      auditArr: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '审核中',
          value: 0
        },
        {
          label: '已通过',
          value: 1
        },
        {
          label: '未通过',
          value: 2
        }
      ],
      approvalForm: {
        carrierId: '',
        status: {}
      },
      columns: [
        {
          title: '承运商名称',
          // width: '10%',
          align: 'center',
          dataIndex: 'carrierName',

        },
        {
          title: '承运商地址',
          dataIndex: 'carrierAddress',
          align: 'center'
          // width: '10%',
        },
        {
          title: '承运商类型',
          dataIndex: 'carrierType.label',
          align: 'center'
        },
        {
          title: '负责人',
          dataIndex: 'charge',
          align: 'center',
          width: '5%'
        },
        {
          title: '负责人电话',
          dataIndex: 'chargeMobile',
          align: 'center',
          width: '7%'
        },
        {
          title: '业务类型',
          dataIndex: 'carrierBusinessType.label',
          align: 'center',
          width: '5%'
        },
        {
          title: '运力属性',
          dataIndex: 'carrierCapacityType.label',
          align: 'center',
          width: '5%'
        },
        {
          title: '是否自有大板',
          dataIndex: 'isBigCar.label',
          align: 'center',
          width: '7%'
        },
        {
          title: '结账方式',
          dataIndex: 'settlementMode.label',
          align: 'center',
          width: '5%'
        },
        {
          title: '是否固化',
          dataIndex: 'isSettled.label',
          align: 'center',
          width: '7%'
        },
        {
          title: '审核状态',
          dataIndex: 'status.label',
          align: 'center',
          width: '5%',
          slots: {
            customRender: 'status'
          }
        },
        {
          title: '审核备注',
          align: 'refuseReason',
          dataIndex: 'refuseReason',
          width: '8%'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '5%',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      rules: {
        status: { required: true, message: '请选择', trigger: 'blur', type: 'object' },
      }
    })
    const changeData = () => {
      loadData()
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }
    // 搜索筛选查询
    const auditChange = (e, v) => {
      state.searchForm.status = v
    }
    const settlementChange = (e, v) => {
      state.settlementMode = v
    }
    const onApprovalEnum = (e, v) => {
      state.approvalForm.status = v
    }
    const onSearch = () => {
      state.loading = true
      state.pagination.current = 1
      loadData()
    }

    const loadData = () => {
      state.loading = true
      getPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize,
        isAudit: 1
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => { console.log(err) })
        .finally(() => { state.loading = false })
    }

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }

    const onSee = (e) => {
      state.carrierId = e.id
      state.detailShow = true
      state.seeWindow = true
      // setTimeout(() => {
      //   _detailData.value.loadData()
      // }, 1)
    }
    // 重置
    const reset = () => {
      state.searchForm = {}
      loadData()
    }
    // 审核
    const onExamine = (e) => {
      console.log('e', e);
      state.approvalForm.carrierId = e.id
      state.addWindow = true
    }
    // 审核
    const onApproval = () => {
      approvalAdd({ ...state.approvalForm }).then(res => {
        if (res.code === 10000) {
          message.info(res.msg)
          loadData()
          state.addWindow = false
        }
      }).catch(err => { console.log(err) })
        .finally(() => { state.loading = false })
    }
    onMounted(() => {
      loadData()
      setTimeout(() => {
        state.CustomerSettlementEnum = store.state.enumAll.CustomerSettlementEnum
        if (state.CustomerSettlementEnum[0].value === '' || state.CustomerSettlementEnum[0].value === 0) state.CustomerSettlementEnum.splice(0, 1)
      }, 200)
    })
    const closeWindow = () => {
      state.detailShow = false
    }

    return {
      ...toRefs(state),
      reset,
      formRef,
      onSearch,
      loadData,
      auditChange,
      closeWindow,
      onSelectChange,
      settlementChange,
      handleTableChange,
      onExamine,
      onApproval, onApprovalEnum, onSee, _detailData,changeData
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../toolDetail/common.less';
</style>
